<template>
  <div>
    <div>
      <div
        style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        "
      >
        <div style="display: inline-block; width: 60px">
          <div>
            <img
              style="width: 100%; border-radius: 50%"
              v-if="mumberXq.NetHeadimg != '' && mumberXq.NetHeadimg != null"
              :src="mumberXq.NetHeadimg"
              alt=""
            />
            <img
              style="width: 100%; border-radius: 50%"
              v-else
              src="../../assets/yeguang.png"
              alt=""
            />
          </div>
          <!-- <div v-else>
            <img style="width: 100%" src="../../assets/yeguang.png" alt="" />
          </div> -->
        </div>
        <div
          style="
            display: inline-block;
            position: absolute;
            top: 30px;
            left: 100px;
          "
        >
          <div v-if="mumberXq.Name" style="font-size: 18px">
            {{ mumberXq.Name }}
          </div>
          <div v-else style="font-size: 18px">成为会员</div>
        </div>
      </div>
      <div class="inputFrom" v-if="verifyshow == 0">
        <!-- <div>
          <van-field
            v-model="idList.OCodeName"
            required
            @click="idListOrg = true"
            label="社区"
            placeholder="请选择社区"
          />
          <van-popup v-model="idListOrg" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              :columns="ocodelist"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg"
            >
            </van-picker>
          </van-popup> -->
        <!-- <input v-model="idList.OCodeName"
            type="text"
            @click="OCodeshow = true"
            readonly="readonly"
            placeholder="请选择您所属社区" />
          <van-popup v-model="OCodeshow"
            position="bottom"
            get-container="body">
            <van-cascader title="请选择所属社区"
              :options="ocodelist"
              @close="OCodeshow = false"
              :field-names="{
                text: 'OrganName',
                value: 'OrganCode',
                children: 'children',
              }"
              @finish="onocode" />
          </van-popup> -->
        <!-- </div> -->
        <!-- <div>网格</div>
        <div>
          <input v-model="idList.GIdTitle"
            type="text"
            @click="idListGId = true"
            readonly="readonly"
            placeholder="请选择您所在网格" />
          <van-popup v-model="idListGId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="gidlist"
              value-key="Title"
              @cancel="idListGId = false"
              @confirm="onidListGId">
            </van-picker>
          </van-popup>
        </div> -->
        <!-- <van-cell-group> -->
        <van-field
          v-model="idList.Name"
          required
          label="姓名"
          placeholder="请输入您的姓名"
        />
        <!-- </van-cell-group> -->
        <!-- <van-cell-group> -->
        <van-field
          v-model="idList.IdCard"
          required
          label="身份证号"
          placeholder="请输入身份证号"
        />
        <!-- </van-cell-group> -->
        <!-- <van-cell-group> -->
        <van-field
          v-model="idList.Mobile"
          required
          label="手机号"
          placeholder="请输入手机号"
          type="tel"
        />
        <!-- </van-cell-group> -->
        <!-- <van-cell-group> -->
        <!-- <van-field
          v-model="idList.Address"
          required
          label="地址"
          type="textarea"
          placeholder="请输入地址"
        /> -->
        <!-- </van-cell-group> -->
        <!-- <div>辖区居民</div>
        <div style="border: 1px solid #e6e6e6; padding: 7px; margin: 10px 25px">
          <van-radio-group v-model="idList.Jurisdiction"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </div>
        <div>党员</div>
        <div style="border: 1px solid #e6e6e6; padding: 7px; margin: 10px 25px">
          <van-radio-group v-model="idList.PartyMember"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </div>
        <div>志愿者</div>
        <div style="border: 1px solid #e6e6e6; padding: 7px; margin: 10px 25px">
          <van-radio-group v-model="idList.Volunteer"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </div>
        <div v-if="idList.Volunteer == '1'">
          <div>专业</div>
          <div>
            <input v-model="idList.Major"
              placeholder="请输入专业" />
          </div>
          <div>兴趣特长</div>
          <div>
            <input v-model="idList.Interest"
              placeholder="请输入兴趣特长" />
          </div>
        </div> -->
      </div>
      <div class="buttons" v-if="verifyshow == 0">
        <van-button
          style="border: 1px solid #e6e6e6"
          type="default"
          @click="fanhui"
          >返回</van-button
        >
        <van-button type="info" @click="huiyuan">提交</van-button>
      </div>
      <div v-if="verifyshow != 0">
        <van-cell-group>
          <!-- <van-field label="社区" :value="mumberXq.OrgName" readonly /> -->
          <van-field
            label="网格"
            :value="mumberXq.GName"
            readonly
            v-if="mumberXq.GName != '' && mumberXq.GName != null"
          />
          <van-field label="身份证号" :value="mumberXq.IdCard" readonly />
          <van-field label="手机号" :value="mumberXq.Mobile" readonly />
          <!-- <van-field label="住址" :value="mumberXq.Address" readonly />
          <van-field label="党员" :value="mumberXq.PartyMemberDesc" readonly /> -->
          <van-field
            label="党组"
            v-if="mumberXq.PartyMember == 1"
            :value="mumberXq.PartyName"
            readonly
          />
          <van-field
            label="党员总积分"
            v-if="mumberXq.PartyMember == 1"
            :value="mumberXq.PmTotalIntegral"
            readonly
          />
          <van-field
            label="党员当前积分"
            v-if="mumberXq.PartyMember == 1"
            :value="mumberXq.PmCurIntegral"
            readonly
          />
          <!-- <van-field label="志愿者" :value="mumberXq.VolunteerDesc" readonly /> -->
          <van-field
            label="志愿者总积分"
            v-if="mumberXq.Volunteer == 1"
            :value="mumberXq.VolTotalIntegral"
            readonly
          />
          <van-field
            label="志愿者当前积分"
            v-if="mumberXq.Volunteer == 1"
            :value="mumberXq.VolCurIntegral"
            readonly
          />
          <van-field
            label="座右铭"
            v-if="mumberXq.Motto != '' && mumberXq.Motto != null"
            :value="mumberXq.Motto"
            readonly
          />
          <van-field
            label="专业"
            v-if="mumberXq.Major != '' && mumberXq.Major != null"
            :value="mumberXq.Major"
            readonly
          />
          <van-field
            label="兴趣爱好"
            v-if="mumberXq.Interest != '' && mumberXq.Interest != null"
            :value="mumberXq.Interest"
            readonly
          />
          <van-field
            label="会员二维码"
            value="点击查看二维码"
            readonly
            @click="qrCodeImage"
          />
        </van-cell-group>
        <van-dialog v-model="qrCodeShow" title="二维码">
          <div style="margin: 20px auto; display: table">
            <!--存放二维码-->
            <div class="qrcode" id="qrcode" ref="ErWeiMaUrl"></div>
          </div>
        </van-dialog>
        <div style="text-align: center; margin-top: 50px">
          <van-button class="btn" @click="memberUpdata">编辑</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import wx from "weixin-js-sdk";
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
import { setOpenId, getOpenId } from "@/utils/auth";
import {
  WeGetGridList,
  GetCodeEntrysList,
  WxApplyLCResident,
  WeGetMemberInfo,
  WeGetAppletOrganList,
  WxIsMember,
  GetOrgansNullToken,
  RegisterMember,
  WeMyMemberInfo,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      gidlist: [],
      NativeCantonCodedata: [],
      ocodelist: [],
      idList: {
        OpenID: "", //用户身份Id、
        OrgCode: "",
        Name: "", //姓名、
        IdCard: "", //身份证号、
        Address: "", //通讯地址、
        Mobile: "", //手机号码、
        Pwd: "", //密码、
      },
      idListGId: false,
      addshow: false,
      verifyshow: "",
      LiveCity: false,
      Political: false,
      OCodeshow: false,
      mumberXq: "",
      qrCodeShow: false, //二维码弹窗
      idListOrg: false,
    };
  },
  created() {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getMemberXq();
    // this.gitocodelist();
    this.MemberInfo();
  },
  methods: {
    // 获取会员提交记录
    // getOpenId()
    MemberInfo() {
      WxIsMember({ OpenID: getOpenId() }).then((res) => {
        if (res.data.code == "0") {
          this.verifyshow = res.data.data;
          console.log(this.verifyshow, "this.verifyshow");
          if (this.verifyshow == 0) {
            // Toast.fail(res.data.msg);
            Toast.fail("未注册会员");
          }
        } else {
          this.getMemberXq();
        }
      });
    },
    getMemberXq() {
      WeMyMemberInfo({ openID: getOpenId() }).then((res) => {
        if (res.data.code == "0") {
          this.mumberXq = res.data.data;
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 点击查看二维码
    qrCodeImage() {
      this.qrCodeShow = true;
      setTimeout(() => {
        this.scEeWeiMa();
      }, 100);
    },
    // 二维码生成
    scEeWeiMa() {
      console.log(this.mumberXq);
      //清除之前的二维码
      this.$refs.ErWeiMaUrl.innerHTML = "";
      // var url = "http://172.18.0.90:8080/#/xuanChuan/" + row.OId
      var url = {
        qrCodeId: this.mumberXq.MId + "",
        qrCodeName: this.mumberXq.Name + "",
        qrCodeType: "9",
      };
      var qrcode = new QRCode(this.$refs.ErWeiMaUrl, {
        text: encodeURIComponent(JSON.stringify(url)), // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L,
      });
      // this.getDownload(row)
    },
    // 提交
    huiyuan: function () {
      this.idList.OpenID = getOpenId();
      console.log(getOpenId());
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

      // if (this.idList.OrgCode == "") {
      //   Toast.fail("请选择社区");
      //   return false;
      // }
      if (this.idList.Name == "") {
        Toast.fail("请输入您的姓名");
        return false;
      }
      if (reg.test(this.idList.IdCard) == false) {
        Toast.fail("请输入正确的身份证号");
        return false;
      }
      if (this.idList.Mobile == "") {
        Toast.fail("请输入手机号");
        return false;
      }
      // if (this.idList.Address == "") {
      //   Toast.fail("请输入住址");
      //   return false;
      // }
      this.idList.OpenID = getOpenId();
      RegisterMember(this.idList).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            if (window.__wxjs_environment === "miniprogram") {
              window.wx.miniProgram.reLaunch({
                url: "/pages/index/index",
              });
            } else {
              alert("请在小程序中打开");
            }
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    fanhui: function () {
      window.wx.miniProgram.reLaunch({
        url: "/pages/index/index",
      });
    },
    // 跳转编辑
    memberUpdata() {
      this.$router.push({
        path: "./memberUpdata",
      });
    },
    gitocodelist() {
      // WeGetAppletOrganList().then((res) => {
      //   this.ocodelist = res.data.data;
      // });
      GetOrgansNullToken({
        level: 4,
        kind: 1,
      }).then((res) => {
        this.ocodelist = res.data.data;
      });
    },
    ridpush: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 选择社区
    onidListOrg(val) {
      this.idList.OrgCode = val.organcode;
      this.idList.OCodeName = val.organname;
      this.idListOrg = false;
      this.getGridList(val.organcode);
    },
    // 小区选择器
    onidListGId(val) {
      this.idList.GId = val.GId;
      this.idList.GIdTitle = val.Title;
      this.idListGId = false;
    },

    // 社区
    onocode(val) {
      this.idList.OCodeName = val.selectedOptions[0].OrganName;
      this.idList.OrgCode = val.value;
      // this.ridpush(val.value);
      this.getGridList(val.value);
      this.OCodeshow = false;
    },
    // 网格
    getGridList: function (row) {
      WeGetGridList({ organCode: row }).then((res) => {
        this.gidlist = res.data.data;
      });
    },
    // 地址
    onLiveCity: function (value) {
      var RegCity = this.addressfrom(value);
      this.idList.NativeCantonCode = RegCity[0];
      this.idList.NativeCantonName = RegCity[1];
      this.LiveCity = false;
    },
    onPolitical(val) {
      this.idList.PoliticalCode = val.Coding;
      this.idList.PoliticalName = val.Name;
      this.Political = false;
    },
    addressfrom(value) {
      // 地址
      var a, b, c;
      a = value.selectedOptions[0];
      b = value.selectedOptions[1];
      c = value.selectedOptions[2];
      var addressCode = c.value;
      var addressName = a.text + "," + b.text + "," + c.text;
      var address = [addressCode, addressName];
      return address;
    },
  },
};
</script>
<style>
.inputFrom {
  margin: 15px 8%;
}
/* .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.inputFrom textarea {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
} */
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}

.btn {
  width: 90%;
  background: linear-gradient(to right, #77c7ef, #187fc8);
  color: #fff;
  line-height: 48px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
}
/deep/ .van-cell {
  margin: -1px 0;
}
</style>